import * as React from 'react';
import '../../styles/global.scss';
import '../../styles/normalize.scss';
import '../../styles/typography.scss';
import '../../styles/settings.scss';
import RootLayout from '../../components/RootLayout/RootLayout';
import Headers from '../../components/Headers/Headers';
import ArticleList from '../../components/ArticleList/ArticleList';
import Footer from '../../components/Footer/Footer';
import ScroolUpButton from '../../components/ScroolUpButton/ScroolUpButton';

const Blog = () => {
  const title_top = 'Blog';
  const title_middle = 'Tips and Inspiration';
  const subtitle =
    'Discover the latest trends, practical tips, and expert advice on web design, SEO, e-commerce, and web applications. Get inspired and grow your online presence!';
  const siteTitle = 'Blog – Tips and Inspiration for Website Development';
  const siteDescription =
    'A blog on website development, SEO, e-commerce, and web applications. Gain practical tips, the latest trends, and inspiration for your online brand.';
  const siteUrl = 'https://www.nowoczesnawitryna.com/blog/';
  const siteUrlOtherLang = 'https://www.nowoczesnawitryna.pl/blog/';

  return (
    <>
      <RootLayout title={siteTitle} url={siteUrl} urlOtherLang={siteUrlOtherLang} description={siteDescription} />
      <Headers title_top={title_top} title_middle={title_middle} subtitle={subtitle} active="blog" />
      <ScroolUpButton />
      <ArticleList />
      <Footer />
    </>
  );
};

export default Blog;
